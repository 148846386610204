// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
	aws_project_region: 'ap-southeast-2',
	aws_cognito_identity_pool_id:
		'ap-southeast-2:1b2a3753-1a4c-45a5-a042-f7b48347df9e',
	aws_cognito_region: 'ap-southeast-2',
	aws_user_pools_id: 'ap-southeast-2_csii9AUl9',
	aws_user_pools_web_client_id: '1rad6ouonov85sl3m3pgc660si',
	oauth: {
		domain: 'auth.amigos.community',
		scope: [
			'phone',
			'email',
			'openid',
			'profile',
			'aws.cognito.signin.user.admin',
		],
		redirectSignIn: 'amigos://',
		redirectSignOut: 'amigos://',
		responseType: 'code',
	},
	federationTarget: 'COGNITO_USER_POOLS',
};

export default awsmobile;
